import React from 'react';
import './App.css';

import _ from 'lodash'
import { BrowserRouter, Route } from 'react-router-dom'

import routes from './routes'

import 'semantic-ui-css/semantic.min.css'
import 'antd/dist/antd.css';
import Favicon from 'react-favicon';

class App extends React.Component {

  shouldComponentUpdate () {
    return false
  }

  render () {
      //console.log(routes)

    return (
          <BrowserRouter>
          {/*
          <Favicon animationDelay={300} animated  url={['/favicon.ico',
                         '/favicon2.ico',
                         '/favicon3.ico',
                         '/favicon4.ico',
                         ]} />
                        */}
            <div style={{ height: '100vh', width: '100%', overflow:'hidden' }}>
              {_.map(routes, ({ component, path, ...extra }, key) => (
                  <Route key={key} exact={extra.exact} path={path} component={component} />
              ))}
            </div>
          </BrowserRouter>
    )
  }
}


export default App;
